@import 'theme/theme';

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
}
.mat-checkbox-layout {
  white-space: normal !important;
}
.mat-checkbox-inner-container {
  margin-top: 4px;
}
